import React, { useEffect, useState } from 'react'

import Layout from '../components/layout/layout';
import Button from '../components/button'
import Spinner from '../components/spinner'
import SEO from '../components/seo'

const ProjectNameGenerator = () => {
  const [wordState, setWordState] = useState({
    project: {},

    _loading: false
  })

  const generateNewName = () => {
    setWordState(prevState => ({ ...prevState, _loading: true }))

    fetch(`${process.env.GATSBY_API_URL}/project-name`)
      .then(response => response.json())
      .then(data => {
        setWordState(prevState => ({
          project: data,
          _loading: false
        }))
      })
  }

  useEffect(() => { generateNewName() }, [])

  return (
        <Layout>
            <SEO title="NFT Project Name Generator" description="Need some name ideas for your next 10K generative NFT project? We got you covered." />

            <div className="m-auto text-center">
                <div className="grid mb-6 rounded-lg">
                    <img
                        className="object-cover object-center h-screen min-h-half-screen opacity-30 w-full rounded-lg"
                        style={{ gridArea: '1/1' }}
                        src={wordState.project.image}
                    />

                    <div className="grid content-evenly m-auto z-10 w-full md:w-2/3 h-half-screen" style={{ gridArea: '1/1' }}>
                        <div className="flex flex-col p-3 md:p-6">
                            <h2 className="uppercase font-bold text-3xl md:text-5xl mb-6 text-white">{wordState.project.name}</h2>
                            <p className="text-gray-200 text-md md:text-xl mb-6">
                                <strong>{wordState.project.summary}</strong>
                                {wordState.project.description}
                            </p>

                            <div>
                                <Button size="xl" action={generateNewName} theme="outline" disabled={wordState._loading} className="text-center mr-6">
                                    {wordState._loading && <Spinner />}
                                    {!wordState._loading && <>Generate new name</>}
                                </Button>
                                <Button theme="blue" size="xl" action={`https://twitter.com/intent/tweet?text=${encodeURIComponent(wordState.project.name)}%3A%20${encodeURIComponent(wordState.project.description)}%20%23TDBC&url=https://topdogbeachclub.com/generator/`} disabled={!wordState.project.name}>
                                    Tweet this
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
  )
}

export default ProjectNameGenerator
